import React from 'react'
import SbEditable from 'storyblok-react'
import FocusPointBg from '../FocusPoint/focusPointBg'
import { Link as GatsbyLink } from 'gatsby'
import IconDownloadOutline from '@ef-global/web-ui-react/lib/icons/IconDownloadOutline'
import { removeProductsFromSlug } from '../../helpers/general'

const ContentLink = props => {
  const { image, slug, title, description, asset } = props

  return (
    <div className="content-link">
      <GatsbyLink to={`/${slug}`} className="content-link__image-wrap">
        <FocusPointBg
          focusPoint={image}
          size={{ width: 608, height: 444 }}
          className="content-link__image"
        />
      </GatsbyLink>
      <div className="content-link__title-group">
        <h4 className="content-link__title ef-h5">{title}</h4>
        {asset && (
          <a href={asset} className="content-link__download-icon">
            <IconDownloadOutline />
          </a>
        )}
      </div>
      <p className="content-link__description">{description}</p>
    </div>
  )
}

const ContentLinks = ({ blok }) => {
  const items = blok.items.map(item => {
    return {
      id: item._uid,
      title: item.custom_title ? item.custom_title : item?.content.name || '',
      description: item.custom_description
        ? item.custom_description
        : item.content.description,
      image: item?.content?.content?.featured_image || {},
      slug: removeProductsFromSlug(item?.content?.full_slug),
      asset:
        typeof item.asset === 'object' && item.asset !== null
          ? item.asset.content.file
          : '',
    }
  })

  return (
    <SbEditable content={blok}>
      <div className="content-links">
        {items.length > 0 ? (
          items.map(item => (
            <ContentLink
              key={item.id}
              title={item.title}
              description={item.description}
              image={item.image}
              slug={item.slug}
              asset={item.asset}
            />
          ))
        ) : (
          <p className="u-mx-auto">Please save and reload to update content</p>
        )}
      </div>
    </SbEditable>
  )
}

export default ContentLinks
