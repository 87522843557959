import React from 'react'
import posed from 'react-pose'
import { Modal } from '@ef-global/web-ui-react/'
import ReactPlayer from 'react-player'
import IconPlay from '@ef-global/web-ui-react/lib/icons/IconPlay'
import IconClose from '@ef-global/web-ui-react/lib/icons/IconClose'

import activeBreakpoint from '../../helpers/breakpoint'
import FocusPointBg from '../FocusPoint/focusPointBg'

const VideoBG = posed.video({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 250 },
  },
})

class FullbleedVideo extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.videoRef = React.createRef()

    this.state = {
      videoReady: false,
      modalIsOpen: false,
    }

    this._handleOpenModal = this._handleOpenModal.bind(this)
    this._handleCloseModal = this._handleCloseModal.bind(this)
    this._handleResize = this._handleResize.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const videoElem = this.videoRef.current

    if (videoElem) {
      videoElem.muted = true
      videoElem.setAttribute('muted', 'true') //see more: https://github.com/facebook/react/issues/10389
      videoElem.setAttribute('autoplay', 'true')

      videoElem.oncanplay = () => {
        if (this._isMounted) {
          this.setState({ videoReady: true })
          this.playVideo()
        }
      }
    }

    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this._handleResize)
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null
      if (
        activeBreakpoint() === 's' &&
        this.props.blok.background_video_url_mobile.length > 0
      ) {
        // only update src if different from current to avoid reloading video
        if (
          this.videoRef.current.src !==
          this.props.blok.background_video_url_mobile
        ) {
          this.videoRef.current.src = this.props.blok.background_video_url_mobile
        }
      } else {
        if (
          this.videoRef.current.src !== this.props.blok.background_video_url
        ) {
          this.videoRef.current.src = this.props.blok.background_video_url
        }
      }
    })
  }

  playVideo() {
    if (this.videoRef.current) {
      this.videoRef.current.play()
    }
  }

  _handleOpenModal() {
    this.setState({ modalIsOpen: true })
  }

  _handleCloseModal() {
    this.setState({ modalIsOpen: false })
  }

  render() {
    return (
      <div className={`fullbleed--video`}>
        {this.props.blok.background_video_url.length > 0 && (
          <div className="fullbleed--video__wrap">
            <FocusPointBg
              className="fullbleed--video__poster"
              focusPoint={this.props.blok.background_video_poster}
              size={{ width: 2000, height: 0 }}
            />
            <VideoBG
              ref={this.videoRef}
              pose={this.state.videoReady ? 'visible' : 'hidden'}
              className="fullbleed--video__bg"
              muted
              playsInline
              loop
              preload="auto"
              poster="data:image/gif,AAAA"
            />
          </div>
        )}
        {this.props.blok.modal_video_url.length > 0 && (
          <div>
            <button
              className="fullbleed--video__btn"
              onClick={this._handleOpenModal}
            >
              <div className="fullbleed--video__btn-inner">
                <IconPlay />
              </div>
            </button>
            <Modal
              isOpen={this.state.modalIsOpen}
              onDismiss={this._handleCloseModal}
              size={'l'}
            >
              <div className="modal__wrap">
                <div className="ef-container">
                  <div className="modal__content">
                    <div className="modal__player-wrap">
                      <ReactPlayer
                        url={this.props.blok.modal_video_url}
                        playing
                        controls
                        className="modal__player"
                        width="100%"
                        height="100%"
                      />
                      <button
                        className="modal-close"
                        onClick={this._handleCloseModal}
                      >
                        <IconClose style={{ color: 'white' }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

export default FullbleedVideo
