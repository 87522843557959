import React from 'react'
import SbEditable from 'storyblok-react'
import Components from '../components'

const DoDonts = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <div>
        {blok.title && <h5 className="ef-h5">{blok.title}</h5>}

        <div className="do-donts">
          <div className="ef-row">
            {blok.items &&
              blok.items.map(item => (
                <div
                  className={`ef-col -s-6 -m-${
                    blok.items.length <= 3 ? '4' : '3'
                  } u-s-mb-m u-m-mb-s u-l-mb-m`}
                  key={item._uid}
                >
                  {React.createElement(Components(item.component), {
                    key: item._uid,
                    blok: item,
                  })}
                </div>
              ))}
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default DoDonts
