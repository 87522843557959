import React from 'react'
import SbEditable from 'storyblok-react'
import IconIsValid from '@ef-global/web-ui-react/lib/icons/IconIsValid'
import IconIsInvalid from '@ef-global/web-ui-react/lib/icons/IconIsInvalid'

import FocusPointImg from '../FocusPoint/focusPointImg'

const DoDontsItem = props => {
  const renderImage = item => {
    const imageMaxSize = {
      width: 400,
      height: 400,
    }

    const aspectRatio =
      item.aspect_ratio === 'original'
        ? [item.image.imageSize.width, item.image.imageSize.height]
        : item.aspect_ratio !== ''
        ? item.aspect_ratio.split(':').map(Number)
        : [3, 2] //default aspect ratio

    const size = {
      width: Math.floor(imageMaxSize.width),
      height: Math.floor(
        imageMaxSize.height / (aspectRatio[0] / aspectRatio[1])
      ),
    }

    return (
      <FocusPointImg
        focusPoint={item.image}
        size={size}
        alt={item.caption}
        className="ef-card--teaser__img"
      />
    )
  }

  return (
    <SbEditable content={props.blok}>
      <div className="do-donts__item">
        <div className="do-donts__item-header">
          {props.blok.valid ? <IconIsValid /> : <IconIsInvalid />}
          <p className="ef-text-caption">{props.blok.title}&nbsp;</p>
        </div>

        {props.blok.images &&
          props.blok.images.map(item => {
            return <div key={item._uid}>{renderImage(item)}</div>
          })}
      </div>
    </SbEditable>
  )
}

export default DoDontsItem
