import React, { useEffect, useState } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import SbEditable from 'storyblok-react'
import IconMail from '@ef-global/web-ui-react/lib/icons/IconMail'

import Components from '../components.js'
import SEO from '../seo'
import { removeProductsFromSlug } from '../../helpers/general'
import { useAllStoryblokProducts } from '../../hooks/useAllStoryblokProducts'
import OtherGuidelinesList from '../OtherGuidelines/other-guidelines-list'

const Homepage = props => {
  const allStoryblokProducts = useAllStoryblokProducts()
  const [filteredStoryblokProducts, setFilteredStoryblokProducts] = useState([])
  useEffect(() => {
    setFilteredStoryblokProducts(
      allStoryblokProducts.filter(
        product => product.parent_id !== props.story.parent_id
      )
    )
  }, [])

  return (
    <>
      <SEO
        title="Homepage"
        pathname={
          props.story.slug === 'home'
            ? ''
            : removeProductsFromSlug(props.story.full_slug)
        }
      />
      <SbEditable content={props.blok}>
        {props.blok.hero &&
          props.blok.hero.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              sectionUuid: props.story.uuid,
            })
          )}
        {props.blok.body &&
          props.blok.body.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              sectionUuid: props.story.uuid,
            })
          )}
      </SbEditable>
      <OtherGuidelinesList
        cards={filteredStoryblokProducts}
        title="Explore other guidelines"
        background={props.blok.accent_color}
      />
      <div className="ef-section -pt-s -pb-s">
        <div className="ef-container">
          <div className="ef-row -y-center">
            <div className="ef-col -s-12 -m-4">
              <h5>Questions, contributions, feedback or ideas?</h5>
            </div>
            <div className="ef-col -s-12 -m-4 -l-3 -l-offset-2 u-mb-m">
              <OutboundLink
                href="https://teams.microsoft.com/l/team/19%3a8c224395df0d425ea22282b97a7cbb26%40thread.skype/conversations?groupId=75caac76-cab1-4988-9a4a-da0c963e1639&tenantId=f0d1c6fd-dff0-486a-8e91-cfefefc7d98d"
                className="contact__link -teams"
                target="_blank"
              >
                <div className="contact__icon u-mr-s">
                  <span className="ef-icon -social-msteams -light" />
                </div>
                <div className="contact__desc">
                  <p className="contact__title">Chat with us</p>
                  Global Creative Teams
                </div>
              </OutboundLink>
            </div>
            <div className="ef-col -s-12 -m-4 -l-3 u-mb-m">
              <OutboundLink
                href="mailto:global.creative@ef.com"
                className="contact__link -email"
              >
                <div className="contact__icon u-mr-s">
                  <IconMail className="-light" />
                </div>
                <div className="contact__desc">
                  <p className="contact__title">Send us an Email</p>
                  Questions or feedback
                </div>
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Homepage
