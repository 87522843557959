import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import FocusPointBg from '../FocusPoint/focusPointBg'
import { classNames } from '../../helpers/general'
import { ButtonGroup, Button, Modal } from '@ef-global/web-ui-react'
import IconPlay from '@ef-global/web-ui-react/lib/icons/IconPlay'
import IconArrowDown from '@ef-global/web-ui-react/lib/icons/IconArrowDown'
import ReactPlayer from 'react-player'
import { animateScroll } from 'react-scroll'

const ArrowButton = props => {
  const { className, children, ...rest } = props
  const classes = classNames('ef-button', '-arrow-button', className)
  return (
    <button className={classes} {...rest}>
      <span className="ef-button__content">
        {children}
        <span className="arrow-button__icon">
          <IconArrowDown />
        </span>
      </span>
    </button>
  )
}

const FullbleedHero = props => {
  const [open, setOpen] = useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const classes = classNames(
    'ef-fullbleed',
    'fullbleed',
    'hero',
    props.blok.text_color === 'light' && '-light-text'
  )
  const hasButtons = props.blok.video_link.cached_url || props.blok.cta_title
  const scroll = () => {
    const section = document.querySelector('.ef-section')
    const sectionOffset = section
      ? section.getBoundingClientRect().top + window.scrollY - 64
      : 0
    animateScroll.scrollTo(sectionOffset)
  }

  return (
    <SbEditable content={props.blok}>
      <div className={classes}>
        {props.blok.image ? (
          <FocusPointBg
            className="ef-fullbleed__bg"
            focusPoint={props.blok.image}
            size={{ width: 2000, height: 800 }}
          />
        ) : (
          <div className="ef-fullbleed__bg ef-fullbleed__bg--placeholder">
            <div className="u-xy-abs-center">Please select an image</div>
          </div>
        )}
        <div className="ef-fullbleed__content hero-content">
          <div className="ef-container hero-container">
            <div className="ef-stack -end hero-inner-content">
              <div className="ef-stack__item">
                <h2 className="ef-h3 hero-title">{props.blok.title}</h2>
              </div>
              {hasButtons && (
                <div className="ef-stack__item">
                  <ButtonGroup>
                    {props.blok.video_link.cached_url && (
                      <Button secondary filled onClick={openModal}>
                        <IconPlay className="u-mr-xs" />
                        Play video
                      </Button>
                    )}
                    {props.blok.cta_title && (
                      <ArrowButton onClick={scroll}>
                        {props.blok.cta_title}
                      </ArrowButton>
                    )}
                  </ButtonGroup>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={open}
        onDismiss={closeModal}
        size={'l'}
        lightbox
        className="hero-modal"
      >
        <ReactPlayer
          url={props.blok.video_link.cached_url}
          playing
          width="100%"
          height="100%"
          controls
        />
      </Modal>
    </SbEditable>
  )
}

export default FullbleedHero
