import React, { useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { Carousel } from '@ef-global/web-ui-react/lib/components/Carousel'
import Link from '../Link/link'
import { compareDesc } from 'date-fns'

const SectionNewsItem = ({ children, link, ...rest }) =>
  link.url === '' && link.cached_url === '' ? (
    <div {...rest}>{children}</div>
  ) : (
    <Link storyblokLink={link} {...rest}>
      {children}
    </Link>
  )

const SectionNews = props => {
  const [filteredItems, setFilteredItems] = useState(null)
  const swiperConfig = {
    centeredSlides: false,
    loop: false,
    resistanceRatio: 0.15,
    spaceBetween: 16,
    slidesPerView: 1.1,
    breakpoints: {
      768: {
        spaceBetween: 24,
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slideVisibleClass: '-visible',
      },
    },
  }
  const accentColor =
    !!props.blok.accent_color.color && props.blok.accent_color.color
  const dateColor =
    !!props.blok.text_accent_color.color && props.blok.text_accent_color.color

  useEffect(() => {
    const filtered = []
    props.blok.items.sort(compareDesc).forEach(item => {
      item.date = item.date.split(' ')[0].replace(/-/g, '/')
      filtered.push(item)
    })

    setFilteredItems(filtered)
  }, [props.blok.items])
  return (
    <SbEditable content={props.blok}>
      <section className="ef-section section-news">
        <div
          className="section-news__dip"
          style={{ backgroundColor: accentColor }}
        />
        <div className="ef-container">
          <h2 className="ef-h4">{props.blok.title}</h2>
          <Carousel
            swiperConfig={swiperConfig}
            className="section-news__carousel"
          >
            {filteredItems &&
              filteredItems.map((item, i) => (
                <SectionNewsItem
                  className="section-news-item"
                  style={{ borderColor: accentColor }}
                  link={item.link}
                  key={i}
                >
                  <p
                    className="ef-text-caption section-news-item__date"
                    style={{ color: dateColor }}
                  >
                    {item.date}
                  </p>
                  <h2 className="section-news-item__title">{item.title}</h2>
                  <p className="ef-text-caption section-news-item__description">
                    {item.description}
                  </p>
                </SectionNewsItem>
              ))}
          </Carousel>
        </div>
      </section>
    </SbEditable>
  )
}

export default SectionNews
