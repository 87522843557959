import { injectScopedInternalComponentList } from '@ef-global/sbc-utils'

// content types
import Page from './content-types/page'
import Homepage from './content-types/homepage'
import SubsectionPage from './content-types/subsection-page'
import SectionPage from './content-types/section-page'
import Asset from './content-types/asset'
import AssetIndex from './content-types/asset-index'

// nestable components
import ComponentNotFound from './component_not_found'
import SectionCard from './SectionCard/section-card'
import Intro from './Intro/intro'
import Image from './Image/image'
import AssetCard from './AssetCard/asset-card'
import ColorBox from './ColorBox/color-box'
import Section from './Section/section'
import SectionNews from './SectionNews/section-news'
import SectionHighlight from './SectionHighlight/section-highlight'
import Row from './Row/row'
import Column from './Column/column'
import Card from './Card/card'
import ContentLinks from './ContentLinks/content-links'
import LinkCard from './LinkCard/link-card'
import Markdown from './Markdown/markdown'
import TextBlock from './TextBlock/text-block'
import DoDonts from './DoDonts/do-donts'
import DoDontsItem from './DoDonts/do-donts-item'
import Fullbleed from './Fullbleed/fullbleed'
import FullbleedHero from './FullbleedHero/fullbleed-hero'
import FullbleedVideo from './FullbleedVideo/fullbleed-video'
import ImageAdvanced from './ImageAdvanced/image-advanced'
import VideoCard from './VideoCard/video-card'
import News from './News/news'
import RelatedItems from './RelatedItems/related-items'

// --- sb-mig scoped component imports ---
import * as ScopedWebUiCarousel from '@ef-global/sbc-web-ui-carousel'
import * as VideoComponents from '@ef-global/sbc-web-ui-video-component'

const withComponentList = type => componentList => {
  if (typeof componentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return componentList[type]
}

const Components = type => withComponentList(type)(ComponentList)

const LocalComponentList = {
  //Content types - page templates
  page: Page,
  homepage: Homepage,
  'subsection-page': SubsectionPage,
  'section-page': SectionPage,
  'asset-index': AssetIndex,
  //Nestable components
  section: Section,
  'section-news': SectionNews,
  'section-highlight': SectionHighlight,
  row: Row,
  column: Column,
  card: Card,
  'content-links': ContentLinks,
  markdown: Markdown,
  intro: Intro,
  'do-donts-item': DoDontsItem,
  'do-donts': DoDonts,
  'section-card': SectionCard,
  'focuspoint-image': Image,
  'text-block': TextBlock,
  asset: Asset,
  'asset-card': AssetCard,
  'color-box': ColorBox,
  fullbleed: Fullbleed,
  'fullbleed-video': FullbleedVideo,
  'fullbleed-hero': FullbleedHero,
  'image-advanced': ImageAdvanced,
  'video-card': VideoCard,
  news: News,
  'related-items': RelatedItems,
  'link-card': LinkCard,
}

const ScopedComponentList = {
  // The injectScopedInternalComponentList is method to inject whole components.js file if scope
  // component have more internal components than just one
  ...injectScopedInternalComponentList(
    {
      components: [
        // --- sb-mig scoped component list ---
        ScopedWebUiCarousel.ComponentList,
        VideoComponents.ComponentList,
      ],
    },
    Components // injecting all Components you will use normally, into any of scoped components
  ),
  // If you are using scoped component, but inside your local project (installed with sb-mig add component-name --copy)
  // then use this syntax to import and use component and to inject component list to it
  // surface: withComponents(Surface)(Components)
  // "web-ui-surface": withComponents(SurfaceStuff.WebUISurface)(Components)
}

const ComponentList = {
  ...LocalComponentList,
  ...ScopedComponentList,
}

export default Components
