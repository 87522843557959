import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'
import Link from '../Link/link'
import FocusPointImg from '../FocusPoint/focusPointImg'
import FocusPointBg from '../FocusPoint/focusPointBg'

const Card = props => {
  return (
    <SbEditable content={props.blok}>
      {props.blok.type === 'content' ? (
        <div className="ef-card--content">
          <FocusPointBg
            customTag={Link}
            focusPoint={props.blok.image}
            size={{ width: 1000, height: 600 }}
            storyblokLink={props.blok.link}
            className="ef-card--content__img"
            tabIndex="-1"
          />
          <div className="ef-card--content__inner">
            <div className="ef-card--content__content">
              <h4 className="ef-card--content__title">{props.blok.title}</h4>
              <div className="ef-card--content__text">
                <ReactMarkdown
                  transformLinkUri={uri => uri.replace(/^\/products/, '')}
                  source={props.blok.description}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Link className="ef-card--teaser" storyblokLink={props.blok.link}>
          <FocusPointImg
            focusPoint={props.blok.image}
            size={{ width: 800, height: 532 }}
            alt={props.blok.title}
            className="ef-card--teaser__img"
          />
          <div className="ef-card--teaser__inner">
            <div className="ef-card--teaser__content">
              <h4 className="ef-card--teaser__title">{props.blok.title}</h4>
              <div className="ef-card--teaser__text">
                <ReactMarkdown
                  transformLinkUri={uri => uri.replace(/^\/products/, '')}
                  source={props.blok.description}
                />
              </div>
            </div>
          </div>
        </Link>
      )}
    </SbEditable>
  )
}

export default Card
