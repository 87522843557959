import React from 'react'
import { Link } from 'react-scroll'
import IconArrowRight from '@ef-global/web-ui-react/lib/icons/IconArrowRight'

import { useStartpageFromParent } from '../hooks/storyblokEntries'

const SubsectionIntro = props => {
  const parentStartStory = useStartpageFromParent(props.story.parent_id)
  const filteredSections = props.story.content.body
    ? props.story.content.body.filter(section => section.title)
    : []
  const { background, divider } = props.story.content

  return (
    <section
      className="ef-section intro__section -sub"
      style={{ backgroundColor: background && background.color }}
    >
      <div className="ef-container">
        <div
          className="subsection-intro intro"
          style={{ borderBottom: divider && '1px solid #d1d1d1' }}
        >
          <div className="intro__content">
            <div className="story__parent u-mb-s">
              {(!props.story.content.hide_category && parentStartStory.name) ||
                ''}
            </div>
            <h2>{props.story.name}</h2>
            {props.story.content.intro && (
              <p className="ef-text-subtitle">{props.story.content.intro}</p>
            )}
            <ul className="subsection-intro__list-style">
              {filteredSections.map(section => (
                <li key={section._uid}>
                  <IconArrowRight />
                  <Link
                    to={section._uid}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                  >
                    {section.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubsectionIntro
