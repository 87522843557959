import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'
import IconArrowRight from '@ef-global/web-ui-react/lib/icons/IconArrowRight'

import Link from '../Link/link'
import moment from 'moment'
import FocusPointBg from '../FocusPoint/focusPointBg'

const News = props => {
  const firstNews = props.blok.items.length ? props.blok.items[0] : null

  return (
    <SbEditable content={props.blok}>
      <>
        {firstNews && (
          <div className="news-item__w u-mb-xxl">
            <div className="ef-container">
              <div className="ef-row">
                <div className="ef-col -m-5">
                  <div className="news-item__date u-mb-xl">
                    <div className="news-item__date-m">
                      {moment(firstNews.date).format('MMM')}
                    </div>
                    <div className="news-item__date-d">
                      {moment(firstNews.date).format('D')}
                    </div>
                  </div>
                  <h6 className="news-item__tag">News</h6>
                  <h5>{firstNews.title}</h5>
                  <ReactMarkdown
                    className="u-mb-m markdown -light"
                    source={firstNews.description}
                  />
                  {firstNews.link.cached_url.length > 0 && (
                    <p>
                      <Link
                        className="link-arrow"
                        storyblokLink={firstNews.link}
                      >
                        {firstNews.link_title
                          ? firstNews.link_title
                          : 'More info'}
                        <IconArrowRight className="link-arrow__icon" />
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <FocusPointBg
              className="news-item__img"
              focusPoint={firstNews.featured_image}
              size={{ width: 1000, height: 0 }}
              alt={firstNews.title}
            />
          </div>
        )}
        {props.blok.items && (
          <div className="ef-container">
            <div className="ef-row">
              {props.blok.items.map(
                (newsItem, index) =>
                  index > 0 && (
                    <div
                      key={newsItem._uid}
                      className="ef-col -s-12 -m-4 u-mb-xxl u-m-mb-0 news-item"
                    >
                      <div className="u-mb-m ef-row -x-start -y-center">
                        <div className="ef-col -s-shrink">
                          <span className="news-item__date-icon" />
                        </div>
                        <div className="ef-col -s-shrink">
                          {moment(newsItem.date).format('MMMM Do YYYY')}
                        </div>
                      </div>
                      <h5 className="u-mb-xs">{newsItem.title}</h5>
                      <ReactMarkdown
                        className="news-item__desc u-mb-s markdown -light"
                        source={newsItem.description}
                      />
                      {newsItem.link.cached_url.length > 0 && (
                        <p>
                          <Link
                            className="link-arrow"
                            storyblokLink={newsItem.link}
                          >
                            {newsItem.link_title
                              ? newsItem.link_title
                              : 'More info'}
                            <IconArrowRight className="link-arrow__icon" />
                          </Link>
                        </p>
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </>
    </SbEditable>
  )
}

export default News
