import React from 'react'
import SbEditable from 'storyblok-react'
import { Link as GatsbyLink } from 'gatsby'
import { random } from 'lodash'
import IconArrowRight from '@ef-global/web-ui-react/lib/icons/IconArrowRight'

import { useStartpageFromParents } from '../../hooks/storyblokEntries'
import circlesImg from '../../images/homepage_1.svg'
import circles2Img from '../../images/homepage_2.svg'
import FocusPointBg from '../FocusPoint/focusPointBg'
import { removeProductsFromSlug } from '../../helpers/general'

const RelatedItems = props => {
  useStartpageFromParents(props.blok.items)
  const featuredItems = props.blok.items.length && props.blok.items.slice(0, 2)
  const relatedItems = props.blok.items.length > 2 && props.blok.items.slice(2)

  const createIanimList = () => {
    const circles = []
    // Outer loop to create row
    for (let i = 0; i < 9; i++) {
      circles.push(
        <div className="circle-item" key={i}>
          <span className="circle-out" />
          <span
            className="circle-in"
            style={{
              animationDelay: `${random(0, 5)}s`,
              animationDuration: `${random(3, 5)}s`,
            }}
          />
        </div>
      )
    }
    return circles
  }

  return (
    <SbEditable content={props.blok}>
      <div className="" style={{ overflow: 'hidden' }}>
        {featuredItems.map((featuredItem, index) => {
          if (featuredItem.item !== null) {
            return (
              <div className="related-items__w" key={featuredItem._uid}>
                <div className="ef-container">
                  <div className="ef-row -y-center">
                    {index === 0 && (
                      <div className="ef-col -m-6">
                        <div className="ranim">
                          <img src={circlesImg} alt="EF GUD" />
                        </div>
                        <div className="ranim--bg" />
                      </div>
                    )}
                    <div
                      className={`ef-col -s-12 -m-4 u-mt-m u-mb-m ${
                        index === 0 ? '-m-offset-1' : ''
                      }`}
                    >
                      <h6 className="related-items__tag">
                        {featuredItem.startStory.name}
                      </h6>
                      <h3>{featuredItem.title}</h3>
                      <p className="ef-text-para -light">
                        {featuredItem.description}
                      </p>

                      <GatsbyLink
                        to={`/${removeProductsFromSlug(
                          featuredItem.item.full_slug
                        )}`}
                        className="link-arrow"
                      >
                        {featuredItem.link_title.length
                          ? featuredItem.link_title
                          : featuredItem.item.name}
                        <IconArrowRight className="link-arrow__icon" />
                      </GatsbyLink>
                    </div>
                    {index === 1 && (
                      <div className="ef-col -m-7 -m-offset-1">
                        <div className="ianim__w">
                          <div className="ianim--small">
                            <img alt="EF core" src={circles2Img} />
                          </div>
                          <div className="ianim">{createIanimList()}</div>
                        </div>
                        <div className="ianim--bg" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          }
          return null
        })}
      </div>

      {relatedItems && (
        <div className="ef-container">
          <div className="ef-row">
            {relatedItems.map(relatedItem => {
              if (relatedItem.item !== null) {
                return (
                  <div
                    className="ef-col -s-12 -m-4 u-s-mb-l u-m-mb-0"
                    key={relatedItem._uid}
                  >
                    <h6 className="related-items__tag">
                      {relatedItem.startStory.name}
                    </h6>
                    <h5>{relatedItem.title}</h5>
                    {relatedItem.item.content &&
                      relatedItem.item.content.featured_image && (
                        <FocusPointBg
                          customTag={GatsbyLink}
                          to={`/${removeProductsFromSlug(
                            relatedItem.item.full_slug
                          )}`}
                          focusPoint={relatedItem.item.content.featured_image}
                          size={{ width: 600, height: 400 }}
                          className="related-items__bg"
                          tabIndex="-1"
                        />
                      )}
                    <p className="ef-text-para -light">
                      {relatedItem.description}
                    </p>

                    <GatsbyLink
                      to={`/${removeProductsFromSlug(
                        relatedItem.item.full_slug
                      )}`}
                      className="link-arrow"
                    >
                      {relatedItem.link_title.length
                        ? relatedItem.link_title
                        : relatedItem.item.name}
                      <IconArrowRight className="link-arrow__icon" />
                    </GatsbyLink>
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      )}
    </SbEditable>
  )
}

export default RelatedItems
