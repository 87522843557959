import React from 'react'
import Components from '../components'
import SectionCardList from '../SectionCard/section-card-list'
import SEO from '../seo'
import {
  generateListOfSection,
  useAllStoriesWithParent,
} from '../../hooks/storyblokEntries'
import Intro from '../Intro/intro'
import { removeProductsFromSlug } from '../../helpers/general'

const SectionPage = props => {
  const storiesWithSameParent = useAllStoriesWithParent(props.story.parent_id)
  const sectionCardStories = generateListOfSection(
    storiesWithSameParent
  ).filter(story => story.storyUuid !== props.story.uuid)

  return (
    <>
      <SEO
        title={props.story.slug === 'home' ? 'EF Design' : props.story.name}
        pathname={
          props.story.slug === 'home'
            ? ''
            : removeProductsFromSlug(props.story.full_slug)
        }
      />

      <Intro story={props.story} />
      {props.blok.body &&
        props.blok.body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            sectionUuid: props.story.uuid,
          })
        )}
      {!props.blok.hide_related && (
        <SectionCardList
          cards={sectionCardStories}
          parentStartStory={props.story}
          title="Explore"
          className="-pb-l u-pt-0"
          background={props.blok.related_background}
        />
      )}
    </>
  )
}

export default SectionPage
