import React from 'react'
import SbEditable from 'storyblok-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class ColorBox extends React.Component {
  state = {
    isCopied: Array(this.props.blok.items.length).fill(false),
    activeColor: 'color_hex',
  }

  onUpdateCopied = (index, isCopied) => {
    this.setState(state => {
      state.isCopied[index] = isCopied

      return {
        isCopied: state.isCopied,
      }
    })
  }

  render() {
    const colors = [
      {
        key: 'color_hex',
        name: 'HEX',
      },
      {
        key: 'color_rgb',
        name: 'RGB',
      },
      {
        key: 'color_cmyk',
        name: 'CMYK',
      },
      {
        key: 'color_pms',
        name: 'PMS',
      },
    ]

    return (
      <SbEditable content={this.props.blok}>
        <div className="color-box__tabs u-mb-l">
          {colors.map(color => {
            if (
              this.props.blok.items &&
              this.props.blok.items.some(item => item[color.key] !== '')
            ) {
              return (
                <button
                  key={color.key}
                  onClick={() => this.setState({ activeColor: color.key })}
                  className={`color-box__tabs-btn ${
                    this.state.activeColor === color.key ? '-is-active' : ''
                  }`}
                >
                  {color.name}
                </button>
              )
            }
            return null
          })}
        </div>
        <div className="color-box">
          {this.props.blok.items &&
            this.props.blok.items.map((color, index) => (
              <div
                key={color.name}
                className={`color-box__item ${color.white_text ? '-inv' : ''}`}
                style={{ background: `#${color.color_hex}` }}
              >
                <div className="color-box__item-content">
                  {color.name}
                  <p className="ef-text-caption">
                    {color[this.state.activeColor]}
                  </p>
                </div>
                <CopyToClipboard
                  text={color[this.state.activeColor]}
                  onCopy={() => this.onUpdateCopied(index, true)}
                >
                  <button
                    className="color-box__item-copy"
                    onMouseEnter={() => this.onUpdateCopied(index, false)}
                  >
                    {this.state.isCopied[index] ? 'Copied' : 'Copy'}
                  </button>
                </CopyToClipboard>
              </div>
            ))}
        </div>
      </SbEditable>
    )
  }
}

export default ColorBox
