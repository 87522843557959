import React from 'react'
import SbEditable from 'storyblok-react'
import Asset from '../content-types/asset'

const AssetCard = props => {
  return (
    <SbEditable content={props.blok}>
      <Asset story={props.blok.asset} contextBg={props.sectionBg} />
    </SbEditable>
  )
}

export default AssetCard
