import React from 'react'
import SbEditable from 'storyblok-react'
import Components from '../components'

const Column = props => (
  <SbEditable content={props.blok}>
    <div
      className={`ef-col ${props.blok.s_size === '6' ? '-s-6' : '-s-12'}
         ${props.blok.m_size ? '-m-' + props.blok.m_size : '-m-auto'}
    ${props.blok.m_offset ? '-m-offset-' + props.blok.m_offset : ''}`}
    >
      {props.blok.Components &&
        props.blok.Components.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            sectionUuid: props.sectionUuid,
          })
        )}
    </div>
  </SbEditable>
)

export default Column
