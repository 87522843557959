import React from 'react'
import { trackEvent } from '../../helpers/tracking'

class Asset extends React.Component {
  render() {
    const asset = this.props.story
    if (asset === null) return null
    const contextBg = this.props.contextBg

    return (
      <div
        className={`asset ef-surface -rounded ${
          !contextBg || contextBg === '#ffffff' ? '-outline' : ''
        }`}
      >
        <div className="ef-row -y-center">
          <div className="ef-col u-s-mb-m u-m-mb-0">
            <h6 className="u-mb-xxs">{asset.name}</h6>
            <span className="ef-text-ui">
              {asset.content ? asset.content.description : null}
            </span>
          </div>
          <div className="ef-col -s-shrink">
            <a
              className="ef-button -secondary -square asset__btn"
              href={asset.content ? asset.content.file : null}
              download
              onClick={() =>
                trackEvent(
                  'assets',
                  'download',
                  `${asset.name} - ${asset.content.version}`
                )
              }
            >
              Download
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Asset
