import React from 'react'
import ReactMarkdown from 'react-markdown'

const Intro = ({ story }) => {
  const { background, divider } = story.content

  return (
    <section
      className="ef-section intro__section"
      style={{
        backgroundColor: background && background.color,
      }}
    >
      <div className="ef-container">
        <div
          className="intro"
          style={{ borderBottom: divider && '1px solid #d1d1d1' }}
        >
          <div className="intro__content">
            <h2 className="ef-h2">{story.name}</h2>
            <p className="ef-text-subtitle">{story.content.sub_title}</p>
            {story.content.intro && (
              <div className="ef-text-body">
                <ReactMarkdown
                  transformLinkUri={uri => uri.replace(/^\/products/, '')}
                  source={story.content.intro}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
