import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'
import WithSpacing from '../utils/WithSpacingHOC'

const TextBlock = props => {
  const TitleTag = `h${props.blok.title_size}`

  return (
    <SbEditable content={props.blok}>
      <div className={`text-block  ${props.spacing && props.spacing}`}>
        {props.blok.title && <TitleTag>{props.blok.title}</TitleTag>}
        <div
          className={`text-block--${
            props.blok.text_weight === 'light' ? 'light' : ''
          }`}
        >
          <ReactMarkdown
            source={props.blok.content}
            transformLinkUri={uri => uri && uri.replace(/^\/products/, '')}
            className="markdown"
          />
        </div>
      </div>
    </SbEditable>
  )
}

export default WithSpacing(TextBlock)
