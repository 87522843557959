import { useStaticQuery, graphql } from 'gatsby'

const queryAllStoryblokLinks = graphql`
  query {
    allStoryblokLink(
      filter: { slug: { ne: "settings" } }
      sort: { fields: position }
    ) {
      edges {
        node {
          id
          slug
          name
          is_folder
          parent_id
          position
          uuid
          internalId
          is_startpage
        }
      }
    }
  }
`

export const useAllStoryblokLinks = () => {
  const { allStoryblokLink } = useStaticQuery(queryAllStoryblokLinks)

  return allStoryblokLink.edges.map(item => {
    return item.node
  })
}

export const useAllStoryblokLinksFromParent = parentId => {
  const allStoryblokLink = useAllStoryblokLinks()

  return allStoryblokLink.filter(link => {
    return link.parent_id === parentId
  })
}
