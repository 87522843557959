import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { Modal } from '@ef-global/web-ui-react'
import ReactPlayer from 'react-player'
import IconPlay from '@ef-global/web-ui-react/lib/icons/IconPlay'
import IconClose from '@ef-global/web-ui-react/lib/icons/IconClose'

import FocusPointBg from '../FocusPoint/focusPointBg'
import Link from '../Link/link'

const VideoCard = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const _handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const _handleCloseModal = () => {
    setModalIsOpen(false)
  }

  return (
    <SbEditable content={props.blok}>
      <div className="video-advanced">
        <div className="dip-setter">
          <button className="ef-card--video" onClick={_handleOpenModal}>
            <FocusPointBg
              focusPoint={props.blok.poster}
              size={{ width: 1000, height: 600 }}
              className="ef-card--video__img"
              tabIndex="-1"
            />
            <div className="ef-card--video__icon-w">
              <IconPlay className="ef-card--video__icon" />
            </div>
          </button>
        </div>
        {props.blok.caption &&
          props.blok.caption.trim().length > 0 &&
          (props.blok.caption_link &&
          props.blok.caption_link.cached_url.length > 0 ? (
            <p className="video-advanced__caption">
              <Link
                storyblokLink={props.blok.caption_link}
                target={
                  props.blok.caption_link.linktype === 'url'
                    ? '_blank'
                    : undefined
                }
              >
                {props.blok.caption}
              </Link>
            </p>
          ) : (
            <p className="video-advanced__caption">{props.blok.caption}</p>
          ))}
      </div>
      <Modal onDismiss={_handleCloseModal} open={modalIsOpen}>
        <div className="modal__wrap">
          <div className="ef-container">
            <div className="modal__content">
              <div className="modal__player-wrap">
                <ReactPlayer
                  url={props.blok.video_link.cached_url}
                  playing
                  className="modal__player"
                  width="100%"
                  height="100%"
                />
                <button className="modal-close" onClick={_handleCloseModal}>
                  <IconClose style={{ color: 'white' }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </SbEditable>
  )
}

export default VideoCard
