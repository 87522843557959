import React from 'react'
import SectionCard from './section-card'

const SectionCardList = ({
  cards,
  parentStartStory,
  title,
  className,
  divider,
  background,
}) => {
  return cards.length > 0 ? (
    <section
      className={`ef-section section-card__list ${className}`}
      style={{
        background: background && background.color,
      }}
    >
      <div className="ef-container">
        <div className="ef-row">
          <div className="ef-col">
            {divider && <hr className="section-card__sep" />}
            <h4 className="ef-h4 u-mt-l">{title}</h4>
          </div>
        </div>
        <div className="ef-row">
          {cards.map(story => (
            <div
              className="ef-col -s-6 -m-6 -l-4 u-s-mb-m u-m-mb-s u-l-mb-m"
              key={story.uuid}
            >
              <SectionCard story={story} parentStartStory={parentStartStory} />
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : null
}

export default SectionCardList
