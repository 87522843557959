import React from 'react'
import SbEditable from 'storyblok-react'
import Components from '../components.js'
import WithSpacing from '../utils/WithSpacingHOC'
import FocusPointImg from '../FocusPoint/focusPointImg'
import Link from '../Link/link'
import IconArrowRight from '@ef-global/web-ui-react/lib/icons/IconArrowRight'

const SectionHighlight = props => {
  const renderImage = item => {
    if (!item) return {}
    const imageMaxSize = {
      width: 2000,
      height: 2000,
    }

    const aspectRatio =
      item.aspect_ratio === 'original'
        ? [item.image.imageSize.width, item.image.imageSize.height]
        : item.aspect_ratio !== ''
        ? item.aspect_ratio.split(':').map(Number)
        : [3, 2] //default aspect ratio

    const size = {
      width: Math.floor(imageMaxSize.width),
      height: Math.floor(
        imageMaxSize.height / (aspectRatio[0] / aspectRatio[1])
      ),
    }

    return (
      <FocusPointImg
        focusPoint={item.image}
        size={size}
        alt={item.caption}
        className="section-highlight__image"
      />
    )
  }

  const hasLink = props.blok.link_url.url || props.blok.link_url.cached_url

  return (
    <SbEditable content={props.blok}>
      <section
        className={`ef-section
        ${props.blok.text_color === 'light' ? '-inv' : ''}
        ${props.spacing && props.spacing}`}
      >
        <div className="section-highlight__w">
          <div className="section-highlight__content__w">
            <div className="section-highlight__content">
              {props.blok.content &&
                props.blok.content.map(blok =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                    sectionUuid: props.sectionUuid,
                  })
                )}

              {hasLink && (
                <Link
                  storyblokLink={props.blok.link_url}
                  className="link-arrow"
                >
                  {props.blok.link_text}
                  <IconArrowRight className="link-arrow__icon" />
                </Link>
              )}
            </div>
          </div>
          <div className="section-highlight__image__w">
            {renderImage(props.blok.image[0])}
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default WithSpacing(SectionHighlight)
