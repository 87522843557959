import React from 'react'
import GuidelineCard from './guideline-card'

const OtherGuidelinesList = props => {
  return props.cards.length > 0 ? (
    <section
      className={`ef-section section-card__list ${props.className}`}
      style={{
        background: props.background && props.background.color,
      }}
    >
      <div className="ef-container">
        <div className="ef-row">
          <div className="ef-col">
            <h2 className="ef-h4">{props.title}</h2>
          </div>
        </div>
        <div className="ef-row">
          {props.cards.map(story => (
            <div
              className="ef-col -s-12 -m-6 u-s-mb-m u-m-mb-s u-l-mb-m"
              key={story.uuid}
            >
              <GuidelineCard
                story={story}
                background={story.content.accent_color}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : null
}

export default OtherGuidelinesList
