import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import moment from 'moment'
import { animateScroll } from 'react-scroll'
import { globalHistory } from '@reach/router'
import dashify from 'dashify'
import parseStoryFromNode from '../../helpers/parseStory'
import { trackEvent } from '../../helpers/tracking'
import activeBreakpoint from '../../helpers/breakpoint'

const storiesQuery = graphql`
  query {
    allStoryblokEntry(
      filter: {
        full_slug: { regex: "/assets/.*/" }
        is_startpage: { eq: false }
      }
    ) {
      edges {
        node {
          id
          name
          created_at
          published_at
          uuid
          slug
          full_slug
          content
          is_startpage
          parent_id
          group_id
        }
      }
    }
  }
`

const categoriesMap = [
  'Core Assets',
  'Expression Assets',
  'Digital Assets',
  'Office Assets - For internal use only',
]

class AssetIndex extends React.Component {
  componentDidMount() {
    if (globalHistory.location.hash.length > 0) {
      const assetSelected = document.querySelector(globalHistory.location.hash)

      if (assetSelected !== null) {
        animateScroll.scrollTo(
          assetSelected.getBoundingClientRect().top -
            (['s', 'm'].indexOf(activeBreakpoint()) === -1 ? 65 : 0)
        )
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={storiesQuery}
        render={data => {
          const stories = data.allStoryblokEntry.edges
            .filter(item => {
              return item.node.full_slug.startsWith(this.props.story.full_slug)
            })
            .map(story => parseStoryFromNode(story))

          let categories = []
          stories.forEach(story => {
            if (categories.indexOf(story.content.asset_category) === -1) {
              categories.push(story.content.asset_category)
            }
          })

          // merge the map to existing categories to keep control over order
          categories = categoriesMap.concat(
            categories.filter(category => {
              return categoriesMap.indexOf(category) < 0
            })
          )

          // remove the categories without stories
          categories = categories.filter(category => {
            return stories.find(
              story => story.content.asset_category === category
            )
          })

          return (
            <>
              <div className="ef-container">
                <section className="ef-section intro__section">
                  <div className="intro__content">
                    <h1>{this.props.blok.title}</h1>
                    <p className="ef-text-subtitle">
                      {this.props.blok.description}
                    </p>
                  </div>
                </section>
                <section className="ef-section u-p-0 asset__list">
                  {categories.map(category => {
                    return (
                      <div key={category}>
                        {categories.length > 1 && (
                          <h4 className="u-mt-xxl">{category}</h4>
                        )}
                        {stories
                          .filter(
                            story => story.content.asset_category === category
                          )
                          .map(story => {
                            return (
                              <div
                                className="asset-list__item"
                                key={story.id}
                                id={dashify(story.name)}
                              >
                                <div className="ef-row -y-center">
                                  <div className="ef-col -s-12 -m-auto u-s-mb-m u-m-mb-0">
                                    <h6 className="u-mb-xxs">
                                      {story.content.name}
                                    </h6>
                                    <span className="text--normal text--sub">
                                      {story.content.description}
                                    </span>
                                  </div>
                                  <div className="ef-col -s-shrink">
                                    <p className="u-mb-xxs ef-text-ui -light">
                                      Version
                                    </p>
                                    <span className="text--normal text--sub">
                                      {story.content.version}
                                    </span>
                                  </div>
                                  <div className="ef-col">
                                    <p className="u-mb-xxs ef-text-ui -light">
                                      Date
                                    </p>
                                    <span className="text--normal text--sub">
                                      {moment(story.content.date).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </span>
                                  </div>
                                  <div className="ef-col -s-shrink">
                                    <a
                                      className="ef-button -secondary -square"
                                      href={story.content.file}
                                      onClick={() =>
                                        trackEvent(
                                          'assets',
                                          'download',
                                          `${story.content.name} - ${story.content.version}`
                                        )
                                      }
                                      download
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                </section>
              </div>
            </>
          )
        }}
      />
    )
  }
}

export default AssetIndex
