import React from 'react'
import { trim } from 'lodash'
import Components from '../components'
import SectionCardList from '../SectionCard/section-card-list'
import SEO from '../seo'
import {
  useAllStoryblokEntriesParsed,
  useStartpageFromParent,
} from '../../hooks/storyblokEntries'
import SubsectionIntro from '../subsection-intro'
import { useAllStoryblokLinksFromParent } from '../../hooks/storyblokLinks'
import { removeProductsFromSlug } from '../../helpers/general'

const SubsectionPage = props => {
  const stories = useAllStoryblokEntriesParsed()
  const linksWithSameParent = useAllStoryblokLinksFromParent(
    props.story.parent_id
  ).filter(link => !link.is_startpage)

  const parentStartStory = useStartpageFromParent(props.story.parent_id)
  const sectionCardStories = []
  linksWithSameParent.forEach(link => {
    const story = stories.find(story => {
      return (
        link.slug === trim(story.full_slug, '/') &&
        story.uuid !== props.story.uuid
      )
    })
    story && sectionCardStories.push(story)
  })

  return (
    <>
      <SEO
        title={props.story.slug === 'home' ? 'GUD EF Design' : props.story.name}
        pathname={
          props.story.slug === 'home'
            ? ''
            : removeProductsFromSlug(props.story.full_slug)
        }
      />
      <SubsectionIntro story={props.story} />
      {props.blok.body &&
        props.blok.body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            sectionUuid: props.story.uuid,
          })
        )}

      {props.story.parent_id !== 0 && (
        <SectionCardList
          cards={sectionCardStories}
          parentStartStory={parentStartStory}
          divider={props.story.content.read_more_divider}
          title="Read More"
          className="-pb-m u-pt-0"
          background={props.blok.read_more_background}
        />
      )}
    </>
  )
}

export default SubsectionPage
