export const ENGLISH_LIVE_BLUE = '#002130'

export const Breakpoints = {
  s: '0',
  m: '768px',
  l: '1024px',
  xl: '1264px',
}

export const getSpacingClass = (prop, breakpoint, spacingType) => {
  switch (prop) {
    case 'xs':
      return `u-${breakpoint}-${spacingType}-${prop}`
    case 's':
      return `u-${breakpoint}-${spacingType}-${prop}`
    case 'm':
      return `u-${breakpoint}-${spacingType}-${prop}`
    case 'l':
      return `u-${breakpoint}-${spacingType}-${prop}`
    case 'xl':
      return `u-${breakpoint}-${spacingType}-${prop}`
    case 'xxl':
      return `u-${breakpoint}-${spacingType}-${prop}`
    case 'custom':
      return ''
    case 'none':
    default:
      return `u-${breakpoint}-${spacingType}-0`
  }
}
