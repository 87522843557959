import React from 'react'
import { Link } from 'gatsby'
import IconArrowRight from '@ef-global/web-ui-react/lib/icons/IconArrowRight'
import { removeProductsFromSlug } from '../../helpers/general'

import FocusPointBg from '../FocusPoint/focusPointBg'

const SectionCard = props => {
  return (
    <Link
      to={`/${removeProductsFromSlug(props.story.full_slug)}`}
      className="ef-card--content -section-card -vertical"
    >
      {props.story.content.featured_image &&
      props.story.content.featured_image.image ? (
        <FocusPointBg
          focusPoint={props.story.content.featured_image}
          size={{ width: 1000, height: 600 }}
          className="ef-card--content__img"
          tabIndex="-1"
        />
      ) : (
        <div className="ef-card--content__img" tabIndex="-1" />
      )}
      <div className="ef-card--content__inner">
        <div className="ef-card--content__content">
          <h4 className="ef-card--content__title">{props.story.name}</h4>
          <IconArrowRight />
        </div>
      </div>
    </Link>
  )
}

export default SectionCard
