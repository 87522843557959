import React from 'react'
import SbEditable from 'storyblok-react'
import Components from '../components.js'
import WithSpacing from '../utils/WithSpacingHOC'

const Row = props => {
  const verticalAlignmentClass =
    props.blok.columns_vertical_alignment === 'space-between'
      ? '-space-between'
      : ''
  let alignmentClass = ''

  switch (props.blok.columns_alignment) {
    case 'right':
      alignmentClass = '-x-end'
      break
    case 'center':
      alignmentClass = '-x-center'
      break
    default:
  }

  return (
    <SbEditable content={props.blok}>
      <div
        className={`ef-row ${alignmentClass} ${verticalAlignmentClass} ${props.spacing &&
          props.spacing}`}
      >
        {props.blok.columns.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            sectionUuid: props.sectionUuid,
          })
        )}
      </div>
    </SbEditable>
  )
}

export default WithSpacing(Row)
