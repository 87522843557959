import { graphql, useStaticQuery } from 'gatsby'
import parseStoryFromNode from '../helpers/parseStory'

const queryAllStoryblokProducts = graphql`
  query MyQuery {
    allStoryblokEntry(filter: { full_slug: { glob: "products/*" } }) {
      edges {
        node {
          name
          slug
          full_slug
          id
          uuid
          content
          parent_id
          is_startpage
        }
      }
    }
    allStoryblokLink(filter: { slug: { glob: "products/*" } }) {
      edges {
        node {
          real_path
          name
          parent_id
          slug
          id
          uuid
          is_folder
        }
      }
    }
  }
`

export const useAllStoryblokProducts = () => {
  const { allStoryblokEntry, allStoryblokLink } = useStaticQuery(
    queryAllStoryblokProducts
  )
  const newData = allStoryblokEntry.edges.map(entry => {
    const foundLink = allStoryblokLink.edges.filter(link => {
      if (
        link.node.is_folder &&
        link.node.id === `storybloklink-${entry.node.parent_id}-default`
      ) {
        return link
      }
    })

    return {
      ...parseStoryFromNode(entry),
      storyblokLink: foundLink[0].node,
    }
  })

  return newData
}
