import React from 'react'
import { Link } from 'gatsby'

import FocusPointBg from '../FocusPoint/focusPointBg'

const GuidelineCard = props => {
  return (
    <Link
      to={`/${props.story.slug}`}
      className="ef-card--content -section-card -vertical guideline-card"
      style={{
        background: props.background && props.background.color,
      }}
    >
      <div className="ef-row">
        <div className="ef-col -s-6">
          <div className="guideline-card__heading-content">
            <h4 className="guideline-card__subtitle">Guidelines</h4>
            <h4 className="guideline-card__title">
              {props.story.storyblokLink.name}
            </h4>
          </div>
        </div>
        <div className="ef-col -s-6">
          {props.story.content.featured_image &&
          props.story.content.featured_image.image ? (
            <FocusPointBg
              focusPoint={props.story.content.featured_image}
              size={{ width: 1000, height: 600 }}
              className="ef-card--content__img guideline-card__image"
              tabIndex="-1"
            />
          ) : (
            <div
              className="ef-card--content__img guideline-card__image"
              tabIndex="-1"
            />
          )}
        </div>
      </div>
    </Link>
  )
}

export default GuidelineCard
