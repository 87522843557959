import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'
import IconArrowRight from '@ef-global/web-ui-react/lib/icons/IconArrowRight'

import Link from '../Link/link'
import FocusPointImg from '../FocusPoint/focusPointImg'

const LinkCard = props => {
  return (
    <SbEditable content={props.blok}>
      <div
        className="link-card"
        style={{ background: props.blok.background_color.color }}
      >
        <div className="ef-row -y-center">
          <div className="ef-col -s-12 -m-5 -m-offset-1">
            <h5 className="">{props.blok.title}</h5>
            <ReactMarkdown
              className="markdown -light"
              source={props.blok.description}
              transformLinkUri={uri => uri.replace(/^\/products/, '')}
            />
            <div className="">
              <Link
                storyblokLink={props.blok.link}
                target="_blank"
                className="link-arrow u-mt-xl"
              >
                {props.blok.link_title
                  ? props.blok.link_title
                  : props.blok.title}
                <IconArrowRight className="link-arrow__icon -new-window" />
              </Link>
            </div>
          </div>
          <div className="ef-col -m-5 u-show-m-up">
            <FocusPointImg
              focusPoint={props.blok.image}
              size={{ width: 748, height: 580 }}
              alt={props.blok.title}
            />
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default LinkCard
