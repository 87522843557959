import React from 'react'
import Components from '../components.js'
import SEO from '../seo'
import { removeProductsFromSlug } from '../../helpers/general'

const Page = props => {
  return (
    <>
      <SEO
        title={props.story.name}
        pathname={
          props.story.slug === 'home'
            ? ''
            : removeProductsFromSlug(props.story.full_slug)
        }
      />

      {props.blok.body &&
        props.blok.body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            sectionUuid: props.story.uuid,
          })
        )}
    </>
  )
}

export default Page
